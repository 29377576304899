<template>
  <el-dialog
    title="选择结果"
    width="500px"
    class="close-mySelect-DV"
    :visible.sync="dialogVisible"
    :before-close="onClose"
    >
    <div class="DV-content">
      <i class="el-icon-error"></i>
      <div class="txt">
        金币余额不足，扣除失败？
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取 消</el-button>
      <el-button class="to-up" @click="toUp">去充值</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'balanceDialog',
  data() {
    return {
      dialogVisible: false,
      goldNum: 0,
    }
  },
  methods: {
    show({
      goodPrice = 0,
    } = {}) {
      this.dialogVisible = true 
      this.goldNum = goodPrice
    },
    onClose() {
      this.dialogVisible = false
    },
    toUp() {
      this.$router.push('/recharge')
    },
  },
}
</script>

<style scoped lang="scss">
.close-mySelect-DV {
  .DV-success {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    .el-icon-success {
      font-size: 20px;
      color: #3fdaa0;
    }

    .text {
      margin-left: 5px;
      font-size: 18px;
      font-weight: 400;
      color: #373e4f;
      line-height: 24px;
      text-align: center;
    }
  }

  .DV-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .el-icon-error,
    .el-icon-warning {
      font-size: 40px;
      color: #ffc148;
    }

    .txt {
      font-size: 20px;
      font-weight: 400;
      color: #373e4f;
      line-height: 24px;
      margin-top: 20px;
      text-align: center;

      div:nth-child(1) {
        margin-bottom: 12px;
      }
    }

    .txt-width {
      width: 395px;
    }
  }

  .select-link {
    position: relative;

    .buylink {
      color: #2b66ff;
      text-decoration: none;
    }

    .hidden-input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -999;
    }

    .link-url {
      font-size: 16px;
      font-weight: 400;
      color: #373e4f;
      line-height: 30px;
    }
  }

  .result-bottom {
    font-size: 16px;
    font-weight: 400;
    color: #767d8f;
    line-height: 30px;

    span {
      color: #0668f6;
      cursor: pointer;
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: center;

    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 46px;
      border-radius: 4px;
    }

    .to-up {
      background: linear-gradient(0deg, #ff604e, #ffa853);
      color: #fff !important;
    }

    .confirm {
      background: linear-gradient(0deg, #0831ff, #3777ff);
      color: #fff !important;
    }

    .copy-link {
      width: 400px;
    }
  }

  .query-bottom {
    font-size: 16px;
    color: #767d8f;
    text-align: center;
    margin-top: 15px;

    span {
      cursor: pointer;
      color: #0668f6;
    }
  }
}
</style>
