<template>
  <el-dialog title="我要选择" 
    :visible.sync="newSelectDV" 
    width="760px" 
    :before-close="closeNewSelectDV"
    class="new-select-DV">
    <div class="new-select-content">
      <div class="item">
        1、本次服务将消耗<span>{{ goldNum }}金币</span>，该费用是支付给暗度帮助卖家寻找合适闲置链接的<span>服务费</span>；
      </div>
      <div class="item">
        2、服务费支付成功后，请务必在<span>24小时内</span>完成<span>确认</span>该链接在亚马逊美国站点是<span>否为闲置链接</span>，
        <span>24小时后</span>，<span>不再接受</span>任何<span>申诉</span>；
      </div>
      <div class="item">
        3、如暗度推荐的闲置链接已被其它卖家使用，
        <span>不是真正的闲置链接</span>，请<span>保存截图并发起申诉</span>，
        申诉入口：[我的账户]-[消耗记录]-[申诉]；
      </div>
      <div class="item">
        4、申诉将在2个工作日内完成受理，<span>审核成功</span>的3个工作日内<span>退回</span>
        之前收取的<span>金币</span>，被<span>驳回不退金币</span>；
      </div>
      <div class="item">
        5、如遇<span>欺骗性申诉</span>，帐号将被<span>永久冻结</span>。
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-checkbox v-model="buyNoticeFlag" v-if="isShowTip2">不再提示</el-checkbox>
      <el-button @click="closeNewSelectDV">取 消</el-button>
      <el-button
        :disabled="confirmLoading"
        :loading="confirmLoading"
        @click="onConfirm" class="confirm-btn">我已知晓规则并确认订购
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  createOrder,
  userConfig,
  createCarOrder,
} from "@/api/home.js";
export default {
  name: 'chooseDialog',
  data() {
    return {
      newSelectDV: false,
      confirmLoading: false, // 确认订购
      buyNoticeFlag: false,
      isShowTip2: true,
      goldNum: 0,
      goodIds: [],
      isGoldMeet: true,
    }
  },
  methods: {
    show({
      goodPrice = 0,
      buyNoticeFlag,
    } = {}) {
      this.newSelectDV = true 
      this.goldNum = goodPrice
      this.buyNoticeFlag = buyNoticeFlag
    },
    onConfirm() {
      const { buyNoticeFlag } = this
      this.$emit('onConfirm', {
        buyNoticeFlag,
      })
      this.newSelectDV = false
    },
    closeNewSelectDV() {
      this.newSelectDV = false;
    },
    setLoading(payload) {
      this[payload.type] = payload.payload;
    },
    toUp() {
      this.setLoading({
        type: "confirmLoading",
        payload: true,
      })
      this.newSelectDV = false;
      if (this.buyNoticeFlag) {
        userConfig({
          configKey: 'buyorder_notice_flag',
          configValue: 'false',
        })
      }
      if (this.isGoldMeet) {
        // 消耗
        this.createCarOrder()
      } else {
        // 充值
        this.$router.push("/recharge");
      }
    },
    async createCarOrder() {
      const { goodIds } = this
      const res = await createCarOrder({
        goodIds,
      })
      const { success, errorCode } =  res
      if (!success) {
        if (errorCode == 'zombielinkorder.coin.notenough') {
          this.$message.closeAll()
        }
      }
      this.setLoading({
        type: 'confirmLoading',
        payload: false,
      })
    },
    // search-table copy过来的业务繁杂就这样吧 崔的紧就先这样放着留个备案吧后面再说
    createOrder() {
      createOrder({
        goodId: this.goodId,
      }).then((res) => {
        if (!res.success) {
          if (res.errorCode == 'zombielinkorder.coin.notenough') {
            this.$message.closeAll();
            this.isGoldMeet = false;
            this.mySelectDV = true;
          }
        } else {
          this.isResult = true;
          this.mySelectDV = true;
          this.copyVal = res.data.goodUrl;
          this.tableData.some((item) => {
            if (item.goodId == this.goodId) {
              this.$set(item, "buyLinkSuccess", true);
            }
          });
        }
      }).finally(() => {
        this.setLoading({
          type: 'confirmLoading',
          payload: false,
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep.new-select-DV,
::v-deep.close-mySelect-DV {
  .el-dialog__body {
    padding-top: 0 !important;
  }

  .new-select-content {
    color: #373e4f;
    line-height: 36px;
    font-size: 16px;

    span {
      color: #ff455b;
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;

  .el-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 46px;
    border-radius: 4px;
  }

  .to-up {
    background: linear-gradient(0deg, #ff604e, #ffa853);
    color: #fff !important;
  }

  .confirm {
    background: linear-gradient(0deg, #0831ff, #3777ff);
    color: #fff !important;
  }

  .copy-link {
    width: 400px;
  }
}
</style>
