<template>
  <div class="project-value-box">
    <div class="container">
      <div class="txt-title">
        <div class="left-box"></div>
        <span>项目价值</span>
      </div>
      <div class="value-content">
        <div>
          2021年经历了各种封店/封品牌风波，直评权重也已经不行，国际评价不共享等，亚马逊卖家获取优质安全VP Review难度加大，渠道升级，甚至很多卖家已经吓得不敢做测评了。
那如何才能短期内快速增加VP评论、提高Listing的转化率呢，也许充分利用好闲置链接是一个不错的选择。
        </div>
        <!-- <div>
          2021年经历了各种封店/封品牌风波，直评权重也已经不行，国际评价不共享等，亚马逊卖家获取优质安全VP
          Review难度加大，渠道升级，甚至很多卖家已经不敢做测评了，此时沉寂了很久的僵尸链接又被推了出来，亚马逊圈内目前流传的爬僵尸链接系统又成为新的焦点，5w，2w，1w……这么贵，真的好用吗？也许好用，但是太贵了。
        </div> -->
        <h3>项目具体价值点：</h3>
        <div style="text-indent: 0; padding-left:2em;">
          1、可以短期内增加很多vp评论，提高listing的转化，提高新品的数据表现，维护好老品的评价，减少推广的费用，提高产品利润，迅速激活链接；<br/>
          2、可以迅速查询到经营类目与之相匹配的闲置链接进行处理，减少大量的时间成本，提高团队工作效率；<br/>
          3、可以同步跨站点合并好评和直接销售，卖家跨站点销售时，新品缺评价，老品评分低时，卖家可以用来同步国际评价合并，提高Listing评分。卖家国际同步销售时，新品缺评价，可以直接同步到对应站点开展销售，适合于铺货销售，特别是小站点泛铺，可以同步美国闲置链接直接到对应站点销售<br/>
          4、首次推出全品类链接的查询工具，收录了全美超过1000万条闲置链接。<br/>
          5、市面上首款对广大卖家提供服务的工具，也是目前广大中小卖家具有突出性价比查询工具。
        </div>
        <!-- <div>
          1、可以短期内增加很多vp评论，提高listing的转化，提高新品的数据表现，减少推广的费用，提高产品利润，迅速激活链接；
        </div>
        <div>
          2、可以迅速查询到所经营类目的与之相匹配的僵尸链接进行处理，减少大量的时间成本，提高团队工作效率；
        </div>
        <div>3、首款全品类的查询工具，数据涉及上千万链接；</div>
        <div>
          4、市面上首款对广大卖家提供服务的工具，也是目前广大中小卖家最具性价比查询工具；
        </div>
        <div>
          5、最人性化的僵尸链接查询工具，不仅售后服务到位，还有详细的如何使用僵尸链接的详细指引。
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'projectValue',
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style scoped lang="scss">
.container {
  width: 1200px;
}
.project-value-box {
  display: flex;
  justify-content: center;
  padding: 60px 0 80px;
  background-color: #f5f6fa;

  .value-content {
    border-radius: 4px;
    padding: 20px 20px 25px;
    background-color: #fff;
    font-size: 16px;
    color: #373e4f;
    line-height: 30px;
    font-weight: 400;
    text-indent: 2em;
  }
}
.txt-title {
  display: flex;
  margin-bottom: 40px;
  .left-box {
    width: 4px;
    height: 26px;
    border-radius: 2px;
    background-color: #1b4eff;
    margin-right: 16px;
  }
  span {
    font-size: 28px;
    color: #373e4f;
    font-weight: 700;
    margin-top: -7px;
  }
}
</style>
