<template>
  <div>
    <div class="search-box">
      <div id="mainSearchWarp" class="container ad-input-group">
        <!-- <el-select
          v-model="searchParams.brandFlag"
          class="brand-select"
          placeholder="选择有无品牌"
        >
          <el-option
            v-for="item in brandList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <el-cascader v-model="value" ref="cascader" :options="options" @change="handleChange"
          :props="{ checkStrictly: true, expandTrigger: 'hover' }" placeholder="选择分类"
          class="ad-input--suffix kx-input-placeholder"></el-cascader>
        <el-select v-model="searchParams.priceId" class="brand-select" placeholder="选择价格">
          <el-option v-for="item in usePriceList" :key="item.id" :label="item.price" :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="totalReviewTag" class="brand-select" placeholder="选择评论数区间">
          <el-option v-for="(el, index) in totalReviewTagList" :key="index" :label="el.text" :value="el.code">
          </el-option>
        </el-select>
        <el-select v-model="starsTag" class="brand-select" placeholder="选择用户评分区间">
          <el-option v-for="(el, index) in starsTagList" :key="index" :label="el.text" :value="el.code">
          </el-option>
        </el-select>
        <el-select v-model="hasBrand" class="brand-select" placeholder="是否有品牌">
          <el-option label="All" value="-1"></el-option>
          <el-option label="有品牌" value="1"></el-option>
          <el-option label="无品牌" value="0"></el-option>
        </el-select>
        <!-- 请输入英文的关键词搜索 -->
        <el-input placeholder="请输入1-2个英文词搜索" v-model="searchParams.searchData" clearable
          :class="['input-with-select', isSearchData ? 'verify-input' : '']">
          <el-button slot="append" icon="el-icon-search" @click="tapSearchTable"></el-button>
        </el-input>
        <el-button class="btn-reset" @click="reset">重置</el-button>
        <div class="loading" v-show="loading">
          <span class="loading-cont-wrap">
            <img src="../../../assets/home/aa16o-jqbuc蓝色.gif" alt="" />
            <!--
          <span>查询中...</span>
          -->
            <span>数据过多，查询预计需要30秒，请稍等...</span>
          </span>
        </div>
      </div>
      <div class="keyword-search">
        <span>点击体验：</span>
        <span class="test" v-for="(item, index) in keyWordList" :key="index"
          :class="categoryIndex == index ? 'active' : ''">示例关键词{{ index + 1 }}：<span
            @click="searchscmple(item, index)">{{ item.name }}</span></span>
      </div>

      <div class="keyword-search">
        <span>FAQ热搜：</span>
        <span v-for="(el, index) in FAQList" :key="index" @click="toGoFAQ(el)" class="example-item cursor-pointer">{{
        el.label }}</span>
      </div>

      <!-- <div class="pag-tag-wrap mt10">
        <div class="the-lable w110">评论数区间：</div>
        <div class="the-list-wrap">
          <span class="w80 the-cell" :class="totalReviewTag === el.code ? 't-active' : ''"
            v-for="(el, index) in totalReviewTagList" :key="index" @click="switchTotalReviewTag(el)">
            {{ el.text }}
          </span>
        </div>
      </div>

      <div class="pag-tag-wrap">
        <div class="the-lable w110">用户评分区间：</div>
        <div class="the-list-wrap">
          <span class="w80 the-cell" :class="starsTag === el.code ? 't-active' : ''" v-for="(el, index) in starsTagList"
            :key="index" @click="switchStarsTag(el)">
            {{ el.text }}
          </span>
        </div>
      </div> -->
    </div>
    <div :class="['table-box', headerFixed ? 'table-header-fixed' : '']" ref="table-box">
      <div class="container" id="mainTableWarp">
        <div class="table-msg-box">
          <img src="~@/fonts/mark-s.svg" alt="" />
          由于当前页面只展示30条数据，建议多次搜索刷新，找到心仪链接
        </div>
        <el-table :data="tableList" border style="width: 100%" :header-cell-style="{ background: '#eef1f6' }"
          :row-class-name="tableRowClassName">
          <el-table-column prop="showImageSrc" align="center" width="71" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content="商品页面的第一张主题，并且将图片标题变成主图">
                <span slot="reference">主图</span>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <el-popover placement="right-start" width="306" trigger="hover">
                <img :src="scope.row.showImageSrc" class="popver-img" v-if="scope.row.showImageSrc" />
                <img src="../../../assets/home/default.png" class="popver-img" v-else />
                <img :src="scope.row.showImageSrc" slot="reference" class="look-img" v-if="scope.row.showImageSrc" />
                <img src="../../../assets/home/default.png" slot="reference" class="look-img" v-else />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="stars" align="center" width="50" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content='商品页面的"Customer reviews"分值'>
                <span slot="reference">用户评分</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { stars } }">
              <el-popover placement="bottom" width="250" trigger="hover" :close-delay="0"
                :content="`商品在亚马逊美国站评分为${stars}分`">
                <span slot="reference">
                  {{ stars }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="date" align="center" width="160" fixed>
            <template slot="header">
              <el-popover placement="bottom" trigger="hover">
                <div>
                  上架时间：商品页面的"Date First Available"时间<br />
                  最后评论时间：该商品最后一个评价的留评时间
                </div>
                <span slot="reference">时间</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { beginSaleDate, unavailableSaleDate } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0">
                <!-- :content="`商品是${beginSaleDate}上架的；最后一个评价的时间是${unavailableSaleDate}`" -->
                <div>
                  商品是{{ beginSaleDate }}上架的；<br />
                  最后一个评价的时间是 {{ unavailableSaleDate }}
                </div>
                <span slot="reference">
                  <p>商品上架:{{ beginSaleDate }}</p>
                  <p>最后评价:{{ unavailableSaleDate }}</p>
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="level2CategoryName" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content="商品的二级类目">
                <span slot="reference">类目</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { level2CategoryName } }">
              <el-popover placement="bottom" width="225" trigger="hover" :close-delay="0"
                :content="`商品所在类目的二级类目为：${level2CategoryName}`">
                <span slot="reference">
                  {{ level2CategoryName }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column width="60" prop="showBrand" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content='商品页面的"Brand"'>
                <span slot="reference">品牌</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { showBrand } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0" :content="
                showBrand
                  ? `商品的品牌名为: ${showBrand}`
                  : `该商品没有填写品牌`
              ">
                <span slot="reference">
                  <span v-if="showBrand">{{ showBrand }}</span>
                  <div v-else style="min-height: 20px; min-width: 20px"></div>
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="showBrandType" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content="品牌根据是否知名分为知名品牌、亚品牌、无品牌三种">
                <span slot="reference">品牌类型</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { showBrandType } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0">
                <p>
                  <span v-if="showBrandType === '知名品牌'">美国知名品牌</span>
                  <span v-else-if="showBrandType === '亚品牌'">该商品上架时有填商标，但不是美国知名品牌</span>
                  <span v-else>该商品上架时没有填写商标</span>
                </p>
                <span slot="reference">
                  {{ showBrandType }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <!-- totalRating ==> totalReview -->
          <el-table-column width="100" prop="totalReview" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover"
                content='商品页面的"global ratings"，当评价被平台删除后，评价数不会变化，所以会存在评价数和实际看到的评价对不上'>
                <span slot="reference">文字评价数</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { totalReview } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0" :content="`商品有${totalReview}个评价`">
                <span slot="reference">
                  {{ totalReview }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="customerReviews" align="center" width="120" fixed>
            <!-- 商品页面评价中的评分占比共100%，显示顺序为5星-4星-3星-2星-1星，具体显示为各星级评分占比 -->
            <template slot="header">
              <el-popover placement="bottom" trigger="hover"
                content="商品页面评价中的评论和Rating总评分占比共100%，显示顺序为5星-4星-3星-2星-1星，具体显示为各星级评分占比">
                <span slot="reference">评价占比</span>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="hover" :close-delay="0" :content="`商品所有评价中各星级评分占比`">
                <div slot="reference">
                  <div class="progress-box">
                    <div class="progress">
                      <div class="size">
                        {{ getCustomerReviews(scope.row, 5) }}
                      </div>
                      <div :style="{ height: getHeight(scope.row, 5) }"></div>
                      <div class="size">5星</div>
                    </div>
                    <div class="progress">
                      <div class="size">
                        {{ getCustomerReviews(scope.row, 4) }}
                      </div>
                      <div :style="{ height: getHeight(scope.row, 4) }">1</div>
                      <div class="size">4星</div>
                    </div>
                    <div class="progress">
                      <div class="size">
                        {{ getCustomerReviews(scope.row, 3) }}
                      </div>
                      <div :style="{ height: getHeight(scope.row, 3) }">1</div>
                      <div class="size">3星</div>
                    </div>
                    <div class="progress">
                      <div class="size">
                        {{ getCustomerReviews(scope.row, 2) }}
                      </div>
                      <div :style="{ height: getHeight(scope.row, 2) }">1</div>
                      <div class="size">2星</div>
                    </div>
                    <div class="progress">
                      <div class="size">
                        {{ getCustomerReviews(scope.row, 1) }}
                      </div>
                      <div :style="{ height: getHeight(scope.row, 1) }">1</div>
                      <div class="size">1星</div>
                    </div>
                  </div>
                  <div class="ta-center f12">
                    评价总数{{ getTotalView(scope.row) }}个
                  </div>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="showTopStars" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content="商品页面第一页前8个评价的评分">
                <span slot="reference">首页评分情况</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { showTopStars } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0" :content="`商品首页前8个评价具体展示分布`">
                <span slot="reference">
                  {{ showTopStars }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="reviewsImagesNum" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content="商品页面留下的评论中有图片的数量">
                <span slot="reference">链接图片数量</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { reviewsImagesNum } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0"
                :content="`该商品的所有评价中${reviewsImagesNum}张买家留的图片`">
                <span slot="reference">
                  {{ reviewsImagesNum }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="gradeScore" align="center" width="60" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content="根据商品综合信息，本系统给出的评分，分值越高越有价值">
                <span slot="reference">暗度评分</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { gradeScore } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0"
                :content="`商品在暗度算法中得${gradeScore}分，分值越高越有价值`">
                <span slot="reference">
                  {{ gradeScore }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="price" width="120" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover"
                content="该条链接在亚马逊美国站以外的其他国家站点是否可用，绿色为可用，红色为不可用，灰色为暂时未接入统计。">
                <span slot="reference">国家分布</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { detectCountryLinkList } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0" v-if="detectCountryLinkList">
                <div v-html="filterDetectCountryLinkList(detectCountryLinkList)"></div>
                <!-- :content="`该商品在***国家可用；在**国家不可用；在**国家是否可用暂未统计。`" -->
                <span slot="reference">
                  <span class="detectCountry-cell" v-for="(el, index) in detectCountryLinkList" :key="index">
                    <span :class="`detect-color${el.status}`">
                      {{ el.shortName }}
                    </span>
                  </span>
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="price" width="50" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content="选择该链接时需要消耗的金币数，具体被划分为5段，99、49、29、9、4">
                <span slot="reference">金币</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { price } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0" :content="`本条商品链接需要花费${price}金币购买`">
                <span slot="reference">
                  {{ price }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="95" align="center" fixed>
            <template slot-scope="scope">
              <el-popover v-if="scope.row.buyLinkSuccess" placement="bottom" width="300" trigger="hover"
                content="该链接已被选择，请到【我的账户】-【消耗记录】里面查看链接">
                <el-button class="disabled-btn" slot="reference">我要选择</el-button>
              </el-popover>
              <el-button @click="handleTapTable(scope.row.goodId, scope.row)" v-else-if="!isExperience">我要选择</el-button>
              <el-popover v-else placement="bottom" width="300" trigger="hover" content="此为体验关键词，不能被选择，请您手工输入需要的关键词查询">
                <el-button class="disabled-btn" slot="reference">我要选择</el-button>
              </el-popover>

              <el-button
                style="margin: 10px 0 0 0;"
                type="warning"
                @click="onAddCart(scope.row)">
                加购物车
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 我要选择 -->
    <el-dialog :title="titleDV" :visible.sync="mySelectDV" width="500px" :before-close="closeMySelectDV"
      class="close-mySelect-DV">
      <template v-if="!isResult">
        <div class="DV-content">
          <!-- <i
            :class="{
              'el-icon-warning': isGoldMeet,
              'el-icon-error': !isGoldMeet,
            }"
          ></i> -->
          <i class="el-icon-error"></i>
          <div class="txt">
            <!-- {{
              isGoldMeet
                ? `本次选择将消耗${goldNum}个金币，选择后2小时有疑问，可提供凭证申诉。申诉成功后可返原消耗金币`
                : '金币余额不足，扣除失败？'
            }} -->
            金币余额不足，扣除失败？
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeMySelectDV">取 消</el-button>
          <!-- <el-button
            @click="toUp"
            :class="{ 'to-up': !isGoldMeet, confirm: isGoldMeet }"
            >{{ isGoldMeet ? '确定' : '去充值' }}</el-button
          > -->
          <el-button class="to-up" @click="toUp">去充值</el-button>
        </span>
      </template>
      <template v-else>
        <div class="select-link">
          <div class="DV-success">
            <i class="el-icon-success"></i>
            <span class="text"> 选择成功 </span>
          </div>
          <div class="link-url">
            商品链接:<a class="buylink" :href="copyVal" target="_Blank">{{
            copyVal
            }}</a>
          </div>
          <input type="type" v-model="copyVal" ref="input" class="hidden-input" />
        </div>
        <div class="result-bottom">
          <div>如对链接有疑问，可在2小时内申诉。</div>
          <div>
            申诉入口在【我的账户】-【消耗记录】<span @click="handleJump(0)">点击查看</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="tapCopyLink" class="copy-link confirm">复制链接</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 点击查询 -->
    <el-dialog title="查询提示" :visible.sync="queryDV" :width="queryStatus ? '700px' : '500px'"
      :before-close="closeQueryDV" class="close-mySelect-DV">
      <div class="DV-content" v-if="!queryStatus">
        <i :class="{
          'el-icon-warning': searchFreeNum > 0 || queryStatus,
          'el-icon-error': !queryStatus,
        }"></i>
        <div class="txt">
          <div :class="{ 'txt-width': queryStatus }">{{ queryHintTxt }}</div>
        </div>
      </div>
      <div class="new-select-content" v-else>
        <div class="item">
          1、本次查询将消耗<span>{{ searchPrice }}金币</span>，查询结果为空不消耗金币；
        </div>
        <div class="item">
          2、相同的查询条件<span>24小时</span>内重查不消耗金币；
        </div>
        <div class="item">3、查询记录入口：[我的帐户]-[查询记录]。</div>
      </div>
      <!-- <div class="query-bottom" v-if="queryStatus">
        查询记录在【我的账户】-【查询记录】<span @click="handleJump(1)"
          >点击查看</span
        >
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-checkbox v-model="checkBoxDV" v-if="isShowTip && queryStatus">不再提示</el-checkbox>
        <el-button @click="closeQueryDV">取 消</el-button>
        <el-button @click="isSearch" :class="{
          'to-up': !queryStatus,
          confirm: searchFreeNum > 0 || queryStatus,
        }">{{
        searchFreeNum > 0 || queryStatus
        ? "我已知晓规则并确认查询"
        : "去充值"
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="我要选择" :visible.sync="newSelectDV" width="760px" :before-close="closeNewSelectDV"
      class="new-select-DV">
      <!--
      <div class="new-select-content">
        <div class="item">
          1、本次订购将消耗<span>{{ goldNum }}</span
          >金币；
        </div>
        <div class="item">
          2、订购成功后，请务必在<span>2小时内</span>完成<span>闲置链接确认，2小时后，不再接受</span>任何<span>申诉</span>；
        </div>
        <div class="item">
          3、如订购链接<span>可用性存在问题</span>，请<span>保存截图并发起申诉</span>，申诉入口：[我的账户]-[消耗记录]-[申诉]；
        </div>
        <div class="item">
          4、申诉将在2个工作日内完成受理，<span>审核成功</span>的3个工作日内<span>退回金币</span>，被<span>驳回不退金币</span>；
        </div>
        <div class="item">
          5、如遇<span>欺骗性申诉</span>，帐号将被<span>永久冻结</span>。
        </div>
      </div>
      -->
      <div class="new-select-content">
        <div class="item">
          1、本次服务将消耗<span>{{ goldNum }}金币</span>，该费用是支付给暗度帮助卖家寻找合适闲置链接的<span>服务费</span>；
        </div>
        <div class="item">
          2、服务费支付成功后，请务必在<span>24小时内</span>完成<span>确认</span>该链接在亚马逊美国站点是<span>否为闲置链接</span>，
          <span>24小时后</span>，<span>不再接受</span>任何<span>申诉</span>；
        </div>
        <div class="item">
          3、如暗度推荐的闲置链接已被其它卖家使用，
          <span>不是真正的闲置链接</span>，请<span>保存截图并发起申诉</span>，
          申诉入口：[我的账户]-[消耗记录]-[申诉]；
        </div>
        <div class="item">
          4、申诉将在2个工作日内完成受理，<span>审核成功</span>的3个工作日内<span>退回</span>
          之前收取的<span>金币</span>，被<span>驳回不退金币</span>；
        </div>
        <div class="item">
          5、如遇<span>欺骗性申诉</span>，帐号将被<span>永久冻结</span>。
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-checkbox v-model="checkBoxDV2" v-if="isShowTip2">不再提示</el-checkbox>
        <el-button @click="closeNewSelectDV">取 消</el-button>
        <el-button :disabled="confirmLoading" :loading="confirmLoading" @click="toUp" class="confirm-btn">我已知晓规则并确认订购
        </el-button>
      </span>
    </el-dialog>

    <asin-cart
      ref="asinCart"/>
  </div>
</template>

<script>
import {
  searchPriceList,
  searchListCategory,
  searchList,
  queryCoin,
  createOrder,
  searchSamples,
  searchscmple,
  // queryUserCoin,
  userConfig,
} from "@/api/home.js";
import { getItem, removeItem } from "@/utils/storage.js";
import asinCart from './asin-cart.vue';
export default {
  components: { asinCart },
  name: "searchTable",
  props: {},
  data() {
    return {
      hasBrand: '',
      confirmLoading: false, // 确认订购
      // 文字评价数
      totalReviewTagList: [
        {
          code: "All",
          text: "All",
          rangMin: 0,
          rangMax: "",
        },
        {
          code: "0",
          rangMin: 0,
          rangMax: 50,
          text: "0～50",
        },
        {
          code: "1",
          rangMin: 50,
          rangMax: 100,
          text: "50～100",
        },
        {
          code: "2",
          rangMin: 100,
          rangMax: 150,
          text: "100～150",
        },
        {
          code: "3",
          rangMin: 150,
          rangMax: 200,
          text: "150～200",
        },
        {
          code: "4",
          rangMin: 200,
          rangMax: "",
          text: "200以上",
        },
      ],
      // stars 用户评分
      starsTagList: [
        {
          code: "All",
          text: "All",
          rangMin: 0,
          rangMax: "",
        },
        {
          code: "0",
          rangMin: 0,
          rangMax: 3,
          text: "0～3",
        },
        {
          code: "1",
          rangMin: 3,
          rangMax: 4,
          text: "3～4",
        },
        {
          code: "2",
          rangMin: 4,
          rangMax: 4.5,
          text: "4～4.5",
        },
        {
          code: "3",
          rangMin: 4.5,
          rangMax: 5,
          text: "4.5～5",
        },
      ],
      totalReviewTag: "",
      starsTag: "",
      FAQList: [
        {
          id: 7,
          label: "怎么用闲置链接？",
        },
        {
          id: 15,
          label: "怎么修改链接或者同步到其它站点？",
        },
        {
          id: 21,
          label: "怎么充值？",
        },
      ],
      categoryIndex: 0,
      priceList: [],
      value: [],
      options: [],
      tableData: [],
      mySelectDV: false,
      searchParams: {
        priceId: "", // 价格
        pageNo: 1,
        pageSize: 30,
        level1CategoryName: "",
        level2CategoryName: "",
        searchData: "",
        brandFlag: "",
      },
      isSearchData: false,
      titleDV: "选择结果",
      goldNum: 0,
      isGoldMeet: true,
      goodId: "",
      isResult: false,
      copyVal: "",
      loading: false,
      headerFixed: false,
      keyWordList: [],
      queryDV: false,
      searchFreeNum: 0,
      searchPrice: 0,
      queryStatus: false,
      newSelectDV: false,
      isExperience: false,
      brandList: [
        {
          value: "all",
          label: "全部",
        },
        {
          value: "true",
          label: "有品牌",
        },
        {
          value: "false",
          label: "无品牌",
        },
      ],
      checkBoxDV: false,
      isShowTip: true,
      checkBoxDV2: false,
      isShowTip2: true,
      freeSearch: false,
    };
  },
  computed: {
    tableList() {
      let {
        tableData,
        totalReviewTag,
        starsTag,
        totalReviewTagConfig,
        starsTagConfig,
        hasBrand,
      } = this;
      let list = tableData || [];
      if (totalReviewTag) {
        list = this.filterTableListByTag(
          totalReviewTag,
          "totalReview",
          totalReviewTagConfig,
          list
        );
      }
      if (starsTag) {
        list = this.filterTableListByTag(
          starsTag,
          "stars",
          starsTagConfig,
          list
        );
      }
      console.log('list:', list)
      const Type = {
        All: '-1',
        HasType: '1',
        NotHasType: '0',
      }
      switch (hasBrand) {
        case Type.HasType:
          list = list.filter(e => e.showBrand);
          return list;
        case Type.NotHasType:
          list = list.filter(e => !e.showBrand);
          return list;
        case Type.All:
        default:
          return list;
      }
    },
    totalReviewTagConfig() {
      const { totalReviewTagList } = this;
      const config = totalReviewTagList.reduce((obj, el) => {
        obj[el.code] = { ...el };
        return obj;
      }, {});
      return config;
    },
    starsTagConfig() {
      const { starsTagList } = this;
      const config = starsTagList.reduce((obj, el) => {
        obj[el.code] = { ...el };
        return obj;
      }, {});
      return config;
    },
    usePriceList() {
      const { priceList } = this;
      return priceList.map((el) => {
        if (el.id) el.price = el.price + " 金币";
        return el;
      });
    },
    queryHintTxt() {
      if (this.searchFreeNum > 0) {
        return `本次查询将消耗1次免费查询，剩余${this.searchFreeNum}次，确认查询吗？`;
      } else {
        return this.queryStatus
          ? `本次查询将消耗${this.searchPrice}金币，查询为空不收费`
          : `本次查询将消耗${this.searchPrice}金币，您的余额不足，请先充值。`;
      }
    },
  },
  watch: {},
  created() {
    this.searchPriceList();
    this.searchListCategory();
    this.searchSamples();
    // userConfig({
    //   "configKey": 'search_notice_flag',
    //   "configValue": 'true'
    // })
    // userConfig({
    //   "configKey": 'buyorder_notice_flag',
    //   "configValue": 'true'
    // })
  },
  mounted() {
    window.addEventListener("scroll", this.pageScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.pageScroll);
  },
  methods: {
    onAddCart(row) {
      this.$store.commit('addCart', row)
    },
    filterTableListByTag(tagCode, tag, config, list) {
      let useList = [];
      const rang = config[tagCode];
      if (tagCode && rang) {
        const { rangMin, rangMax } = rang;
        useList = list.filter((el) => {
          return (
            rangMin < el[tag] && (rangMax === "" ? true : el[tag] < rangMax)
          );
        });
      } else {
        return list;
      }
      return useList;
    },

    switchTotalReviewTag(el) {
      const { code } = el;
      if (this.totalReviewTag === code) {
        this.totalReviewTag = "";
      } else {
        this.totalReviewTag = code;
      }
    },
    switchStarsTag(el) {
      const { code } = el;
      if (this.starsTag === code) {
        this.starsTag = "";
      } else {
        this.starsTag = code;
      }
    },
    // 重置事件
    reset() {
      this.searchParams.brandFlag = "";
      this.value = [];
      this.searchParams.searchData = "";
      this.searchParams.priceId = "";
      this.starsTag = '';
      this.totalReviewTag = '';
      this.hasBrand = '';
    },
    toGoFAQ(el) {
      // courseId, id
      const { id } = el;
      const newPage = this.$router.resolve({
        name: "Fqa",
        query: { id },
      });
      window.open(newPage.href, "_blank");
      // setItem("askId", id + "");
    },
    filterDetectCountryLinkList(list) {
      let arr = [];
      let arr1 = [];
      let arr0 = [];
      list.forEach((el) => {
        let { status, shortName } = el;
        if (status === "-1") arr.push(shortName);
        if (status === "1") arr1.push(shortName);
        if (status === "0") arr0.push(shortName);
      });
      let str = "该商品";
      if (arr1.length) str += `在[${arr1.splice("、")}]国家可用；</br>`;
      if (arr0.length) str += `在[${arr0.splice("、")}]国家不可用；</br>`;
      if (arr.length)
        str += `在[${arr.splice("、")}]国家是否可用暂未统计。</br>`;
      // <p>该商品在[{{ detectCountryLinkList.filter(el => el.status === '-1').map() }}]国家可用；</p>
      // <p>在[{{ detectCountryLinkList.filter(el => el.status === '-1') }}]国家不可用</p>
      // <p>在[{{ detectCountryLinkList.filter(el => el.status === '-1') }}]国家是否可用暂未统计。</p>

      return str;
    },
    tableRowClassName({ row }) {
      // 金币
      const { price } = row;
      const config = {
        99: "bg-table-99",
        49: "bg-table-49",
        29: "bg-table-29",
        9: "bg-table-9",
        4: "bg-table-4",
      };
      /* let type = 4
      Object.keys(config).sort((a, b) => {
        return Number(b) - Number(a)
      }).some(key => {
        if (Number(price) > Number(key)) {
          type = key
          return true
        }
        return false
      }) */

      return config[price] || "";
    },
    searchSamples() {
      searchSamples().then((res) => {
        if (res.success) this.keyWordList = res.data;
        // this.tapSearchTable(0,this.keyWordList[0],0)
        this.loading = true;
        let panam = {
          sampleNo: "sample1",
        };
        searchscmple(panam).then((res) => {
          this.loading = false;
          if (res.success) {
            this.tableData = res.data.data || [];
          }
        });
      });
    },
    pageScroll() {
      if (this.tableData.length <= 0) return;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (
        this.$refs["table-box"].offsetTop - 75 <= scrollTop &&
        scrollTop <
        this.$refs["table-box"].offsetTop +
        this.$refs["table-box"].offsetHeight
      ) {
        this.headerFixed = true;
      } else {
        this.headerFixed = false;
      }
    },
    searchPriceList() {
      searchPriceList().then((res) => {
        const { data, success } = res;
        if (success) this.priceList = data || [];
      });
    },
    // 获取类目
    searchListCategory() {
      searchListCategory().then((res) => {
        if (res.success) {
          this.options = this.getTreeData(res.data);
          this.options.push({
            label: "All",
            value: "All",
          });
          if (getItem("row")) {
            const row = getItem("row");
            this.setSearchData(row);
            removeItem("row");
            this.tapSearchTable(1);
          }
        }
      });
    },
    getTreeData(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i]["value"] = arr[i]["categoryName"];
        arr[i]["label"] = arr[i]["categoryName"];
        arr[i]["children"] = arr[i]["childCategory"];
        if (arr[i].childCategory && arr[i].childCategory.length > 0) {
          this.getTreeData(arr[i].childCategory);
        }
      }
      return arr;
    },
    // 级联选择框选中
    handleChange(val) {
      this.$refs.cascader.toggleDropDownVisible(false);
      this.searchParams.level1CategoryName = val[0];
      this.searchParams.level2CategoryName = val[1] || "";
    },
    // 搜索
    tapSearchTable(type, val, index) {
      console.log(type, val, index);
      this.categoryIndex = index;
      if (!getItem("token")) {
        this.$eventBus.$emit("openLoginDV");
        return false;
      }
      if (this.searchParams.searchData.trim().length == 0 && type !== 0) {
        this.isSearchData = true;
        this.$message.closeAll();
        this.$message({
          message: "请输入关键词",
          type: "warning",
        });
        return false;
      } else {
        this.isSearchData = false;
      }
      if (type === 0) {
        this.isExperience = true;
        this.searchParams = {
          priceId: "",
          pageNo: 1,
          pageSize: 30,
          level1CategoryName: "",
          level2CategoryName: "",
          searchData: "",
          brandFlag: "",
        };
      } else {
        this.isExperience = false;
      }

      if (type !== 0 && type !== 1) {
        let searchParams = JSON.parse(JSON.stringify(this.searchParams));
        if (searchParams.brandFlag == "") {
          searchParams.brandFlag = "all";
        }
        // 好像是暂时去掉了收费功能
        this.searchList();
        return;
        /* 
        queryUserCoin(searchParams).then(res => {
          if (res.success) {
            this.searchFreeNum = res.data.searchFreeNum
            if (this.searchFreeNum == 0) {
              this.queryStatus =
                res.data.coinNum +
                  res.data.giftCoinNum -
                  res.data.searchPrice >=
                0
                  ? true
                  : false
            }
            this.searchPrice = res.data.searchPrice
            this.isShowTip = res.data.searchNoticeFlag
            this.queryDV = res.data.searchNoticeFlag
            this.freeSearch = res.data.freeSearch
            if (res.data.freeSearch) {
              this.isSearch()
            } else if (!res.data.searchNoticeFlag) {
              if (
                res.data.coinNum + res.data.giftCoinNum - res.data.searchPrice <
                0
              ) {
                this.queryDV = true
              } else {
                this.queryDV = false
                this.searchList()
              }
            }
          }
        })
        */
      } else {
        this.searchList(val);
      }
    },
    searchList(val) {
      this.loading = true;
      this.queryDV = false;
      let searchParams;
      if (this.isExperience) {
        searchParams = {
          priceId: "",
          pageNo: 1,
          pageSize: 30,
          searchData: val.name,
          brandFlag: "",
        };
      } else {
        searchParams = JSON.parse(JSON.stringify(this.searchParams));
      }
      if (searchParams.brandFlag == "") {
        searchParams.brandFlag = "all";
      }
      if (searchParams.level1CategoryName == "") {
        searchParams.level1CategoryName = "All";
      }
      if (this.checkBoxDV) {
        userConfig({
          configKey: "search_notice_flag",
          configValue: "false",
        });
      }
      this.tableData = [];
      this.loading = true;
      searchList(searchParams).then((res) => {
        this.loading = false;
        if (res.success) {
          this.tableData = res.data.data || [];
          if (this.isExperience) {
            this.searchParams = {
              pageNo: 1,
              pageSize: 30,
              level1CategoryName: "",
              level2CategoryName: "",
              searchData: "",
              brandFlag: "",
            };
            this.value = [];
          }
        }
      });
    },
    searchscmple(val, index) {
      this.categoryIndex = index;
      this.loading = true;
      let panam = {
        sampleNo: val.sampleNo,
      };
      searchscmple(panam).then((res) => {
        this.loading = false;
        if (res.success) {
          this.tableData = res.data.data || [];
        }
      });
    },
    handleTapTable(goodId, row) {
      this.goodId = goodId;
      queryCoin({ goodId }).then((res) => {
        if (res.success) {
          this.goldNum = res.data.goodPrice;
          this.newSelectDV = res.data.buyNoticeFlag;
          this.isShowTip2 = res.data.buyNoticeFlag;
          if (!res.data.buyNoticeFlag) {
            this.toUp(row);
          }
        }
      });
    },
    closeMySelectDV() {
      this.mySelectDV = false;
      this.$nextTick(() => {
        setTimeout(() => {
          this.isGoldMeet = true;
          this.isResult = false;
          this.copyVal = "";
        }, 500);
      });
    },
    setLoading(payload) {
      this[payload.type] = payload.payload;
    },
    toUp() {
      /**
       * 2022/4/25 fuxinyi update 确认订购增加重复点击保护
       */
      this.setLoading({
        type: "confirmLoading",
        payload: true,
      });
      this.newSelectDV = false;
      if (this.checkBoxDV2) {
        userConfig({
          configKey: "buyorder_notice_flag",
          configValue: "false",
        });
      }
      if (this.isGoldMeet) {
        // 消耗
        createOrder({
          goodId: this.goodId,
        }).then((res) => {
          if (!res.success) {
            if (res.errorCode == "zombielinkorder.coin.notenough") {
              this.$message.closeAll();
              this.isGoldMeet = false;
              this.mySelectDV = true;
            }
          } else {
            this.isResult = true;
            this.mySelectDV = true;
            this.copyVal = res.data.goodUrl;
            this.tableData.some((item) => {
              if (item.goodId == this.goodId) {
                this.$set(item, "buyLinkSuccess", true);
              }
            });
          }
          this.setLoading({
            type: "confirmLoading",
            payload: false,
          });
        });
      } else {
        // 充值
        this.$router.push("/recharge");
      }
    },
    // 复制链接
    tapCopyLink() {
      this.$refs.input.select();
      document.execCommand("copy");
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    getTotalView(row) {
      const { customerReviews } = row;
      let obj = {};
      try {
        obj = JSON.parse(customerReviews);
      } catch (error) {
        console.log(error);
      }
      return obj.totalView || 0;
    },
    getCustomerReviews(val, index) {
      return JSON.parse(val.customerReviews)[`reviewStar${index}Percent`] ===
        null
        ? "0%"
        : JSON.parse(val.customerReviews)[`reviewStar${index}Percent`];
    },
    getHeight(val, index) {
      let str = JSON.parse(val.customerReviews)[`reviewStar${index}Percent`];
      if (!str) {
        str = 0;
      } else {
        str = str.replace("%", "");
      }
      return str * 0.25 + "px";
    },
    setSearchData(val) {
      this.searchParams.searchData = val.searchRequest.searchData;
      this.searchParams.level1CategoryName =
        val.searchRequest.level1CategoryName;
      this.searchParams.level2CategoryName =
        val.searchRequest.level2CategoryName;
      const arr = [];
      if (val.searchRequest.level1CategoryName) {
        arr.push(val.searchRequest.level1CategoryName);
      }
      if (val.searchRequest.level2CategoryName) {
        arr.push(val.searchRequest.level2CategoryName);
      }
      this.value = arr;
      // if (val.searchRequest.brandFlag == 'all') {
      this.searchParams.brandFlag = val.searchRequest.brandFlag;
      // }
    },
    closeQueryDV() {
      this.queryDV = false;
    },
    isSearch() {
      if (this.searchFreeNum > 0 || this.queryStatus || this.freeSearch) {
        this.searchList();
      } else {
        this.handleJump(2);
      }
    },
    handleJump(index) {
      let str = location.href;
      let pathName = "";
      switch (index) {
        case 0:
          pathName = "/use-record";
          break;
        case 1:
          pathName = "/query-record";
          break;
        case 2:
          pathName = "/recharge";
          break;
        case 3:
          pathName = "/use-record";
          break;
        default:
          break;
      }
      window.open(str.replace(location.pathname, pathName));
    },
    closeNewSelectDV() {
      this.newSelectDV = false;
    },
  },
};
</script>

<style scoped lang="scss">
.detectCountry-cell {
  .detect-color-1 {
    color: #a3aab7;
  }

  .detect-color0 {
    color: #ff0028;
  }

  .detect-color1 {
    color: #04e090;
  }
}

.search-box {
  margin-top: 40px;

  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    width: 1200px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  ::v-deep.brand-select {
    height: 48px;
    // .el-input__inner {

    //   border-radius: 4px 0 0 4px !important;

    // }
    input::-webkit-input-placeholder {
      color: #373e4f !important;
    }

    input::-moz-placeholder {
      color: #373e4f !important;
    }

    input::-moz-placeholder {
      color: #373e4f !important;
    }

    input::-ms-input-placeholder {
      color: #373e4f !important;
    }
  }

  ::v-deep.el-input-group__prepend {
    min-width: 300px;
    height: 48px;
    border: 0;
    padding: 0;
    background-color: #fff;

    input::-webkit-input-placeholder {
      color: #373e4f !important;
    }

    input::-moz-placeholder {
      color: #373e4f !important;
    }

    input::-moz-placeholder {
      color: #373e4f !important;
    }

    input::-ms-input-placeholder {
      color: #373e4f !important;
    }
  }

  .el-cascader {
    width: 300px;
    height: 48px;
  }

  .input-with-select {
    width: 650px;
    height: 48px;
  }

  ::v-deep.el-input-group__append {
    width: 48px;
    border: 0;
    padding: 0;
    background-color: #fff;

    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 40px;
      background: linear-gradient(0deg, #0831ff, #3777ff);
      margin: -9px 0 -1px -1px;
      border-radius: 0 4px 4px 0;

      .el-icon-search {
        font-size: 16px;
        color: #fff;
      }
      &.el-button--default {
        background: linear-gradient(0deg, #0831ff, #3777ff);
      }
      
      &.el-button--warning {

      }
    }
  }

  .btn-reset {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 40px;
    background: linear-gradient(0deg, #0831ff, #3777ff);
    margin: -9px 0 -1px 20px;
    border-radius: 4px;
    color: #fff;
  }

  .keyword-search {
    /* margin-left: -455px; */
    width: 1200px;
    margin: 0 auto;
    text-align: left;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #0668f6;
    line-height: 24px;

    span:nth-child(1) {
      color: #ccc;
      margin-right: 0;
    }

    // span:nth-child(2) {
    //   font-weight: 600;
    // }
    .example-item {
      margin-right: 10px;
      color: #3c7cff;
    }

    .active {
      font-weight: 600;
    }

    .test {
      margin-right: 20px;

      span {
        border-bottom: 1px solid #0668f6;
        color: #0668f6;
        cursor: pointer;
        margin-left: 4px;
      }
    }
  }

  ::v-deep.brand-select {
    .el-input__inner {
      border-radius: 0;
      margin-right: -1px;
    }
  }
}

.table-box {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  position: relative;

  .table-msg-box {
    display: flex;
    align-items: center;
    position: absolute;
    top: -30px;
    right: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #373e4f;
    line-height: 24px;

    img {
      width: 18px !important;
      margin-right: 6px;
    }
  }

  .container {
    width: 1215px;

    img {
      width: 50px;
    }
  }

  ::v-deep.has-gutter {
    color: #373e4f;
  }

  .el-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: 30px;
    background: #FFF;
    &.el-button--default{
      border: 1px solid #0668f6;
      color: #277cf7;
      &:hover {
        color: #409EFF;
        background-color: #ecf5ff;
      }
    }
    &.el-button--warning {
      border: 1px solid #FFA200;
      color: #FFA200;
      &:hover {
        color: #ffaf03;
        background-color: #fdf9f0;
      }
    }
  }

  .disabled-btn {
    cursor: no-drop;
    border: 1px solid #a3aab7;
    color: #a3aab7;
  }

  ::v-deep.el-table__header {
    .cell {
      color: #373e4f;
    }
  }

  .progress-box {
    display: flex;
    justify-content: space-between;
    width: 108px;

    .progress {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      margin-left: -10px;

      .size {
        width: 32px;
        font-size: 12px;
        transform: scale(0.8);
      }

      div:nth-child(2) {
        width: 6px;
        height: 25px;
        border-radius: 3px;
        color: transparent;
      }
    }

    .progress:nth-child(1) {
      div:nth-child(2) {
        background: #ff455b;
      }
    }

    .progress:nth-child(2) {
      div:nth-child(2) {
        background: #fdb944;
      }
    }

    .progress:nth-child(3) {
      div:nth-child(2) {
        background: #00ffa2;
      }
    }

    .progress:nth-child(4) {
      div:nth-child(2) {
        background: #0668f6;
      }
    }

    .progress:nth-child(5) {
      div:nth-child(2) {
        background: #5883ff;
      }
    }
  }
}

.table-header-fixed {
  ::v-deep.el-table__fixed-header-wrapper {
    position: fixed;
    top: 75px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 99;
  }
}

.close-mySelect-DV {
  .DV-success {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    .el-icon-success {
      font-size: 20px;
      color: #3fdaa0;
    }

    .text {
      margin-left: 5px;
      font-size: 18px;
      font-weight: 400;
      color: #373e4f;
      line-height: 24px;
      text-align: center;
    }
  }

  .DV-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .el-icon-error,
    .el-icon-warning {
      font-size: 40px;
      color: #ffc148;
    }

    .txt {
      font-size: 20px;
      font-weight: 400;
      color: #373e4f;
      line-height: 24px;
      margin-top: 20px;
      text-align: center;

      div:nth-child(1) {
        margin-bottom: 12px;
      }
    }

    .txt-width {
      width: 395px;
    }
  }

  .select-link {
    position: relative;

    .buylink {
      color: #2b66ff;
      text-decoration: none;
    }

    .hidden-input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -999;
    }

    .link-url {
      font-size: 16px;
      font-weight: 400;
      color: #373e4f;
      line-height: 30px;
    }
  }

  .result-bottom {
    font-size: 16px;
    font-weight: 400;
    color: #767d8f;
    line-height: 30px;

    span {
      color: #0668f6;
      cursor: pointer;
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: center;

    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 46px;
      border-radius: 4px;
    }

    .to-up {
      background: linear-gradient(0deg, #ff604e, #ffa853);
      color: #fff !important;
    }

    .confirm {
      background: linear-gradient(0deg, #0831ff, #3777ff);
      color: #fff !important;
    }

    .copy-link {
      width: 400px;
    }
  }

  .query-bottom {
    font-size: 16px;
    color: #767d8f;
    text-align: center;
    margin-top: 15px;

    span {
      cursor: pointer;
      color: #0668f6;
    }
  }
}

.popver-img {
  width: 280px;
  height: 280px;
}

.look-img {
  width: 70px;
  height: 40px;
}

::v-deep.new-select-DV,
::v-deep.close-mySelect-DV {
  .el-dialog__body {
    padding-top: 0 !important;
  }

  .new-select-content {
    color: #373e4f;
    line-height: 36px;
    font-size: 16px;

    span {
      color: #ff455b;
    }
  }

  .el-dialog__footer {
    position: relative;
    padding: 0 32px 20px;

    .dialog-footer {
      display: flex;
      // justify-content: space-between;
      justify-content: center;

      .el-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 46px;
        color: #373e4f;
      }

      .confirm-btn {
        background: linear-gradient(0deg, #0831ff, #3777ff);
        color: #fff;
        // margin-left: 50px;
      }

      .el-checkbox {
        position: absolute;
        bottom: 32px;
        left: 22px;
      }
    }
  }
}

.container {
  position: relative;

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*
    height: 130px;
    */
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);

    img {
      width: 15%;
    }

    .loading-cont-wrap {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 95px;
      left: 50%;
      transform: translateX(-48%);
      font-weight: 500;
      color: #0668f6;
    }

    /*
    span {
      position: absolute;
      top: 95px;
      left: 50%;
      transform: translateX(-48%);
      font-weight: 500;
      color: #0668f6;
    }
    */
  }
}
</style>
