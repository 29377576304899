<template>
  <div id="videoListBox" class="app-video-list-wrap">
    <div class="ad-container">
      <div class="ad-la-title">
        视频教程
      </div>
      <div class="kad-card">
       <!--  <div v-for="(el, index) in list" :key="index">
          {{ el.title }}
        </div> -->

        <el-carousel
          class="veido-carousel-wrap"
          indicator-position="none"
          :arrow="carouselArrow"
          :interval="5000" 
          :height="'160px'">
          <el-carousel-item v-for="(list, index) in carouselList" :key="index">
            <template v-if="list">
              <div class="carousel-list-wrap">
                <div
                  :class="item.id ? 'the-cell' : 'the-cell seat-cell'"
                  v-for="item in list" :key="item.id"
                  @click="playVideo(item)">
                  <i class="the-mask"></i>
                  <div class="btn-center-wrap">
                    <i class="icon-play btn-play"></i>
                  </div>
                  <img v-if="item.id" :src="item.posterUrl"/>
                </div>
              </div>
            </template>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { groupArray } from '@/utils'
import { getTutorialVideos } from '@/api/home.js'
export default {
  name: 'video-list', // 视频教程
  components: {},
  props: {},
  data() {
    return {
      list: [],
      carouselList: [],
    }
  },
  computed: {
    carouselArrow() {
      const { carouselList } = this
      return carouselList.length > 1 ? 'hover' : 'never'
    },
  },
  watch: {},
  created() {
    getTutorialVideos().then(res => {
      console.log('res', res)
      const { data, message, success } = res
      if (success) {
        const carouselList = groupArray([...data], 5)
        this.carouselList = carouselList
      } else {
        this.$message.error(message)
      }
    })
  },
  methods: {
    playVideo(item) {
      window.open(item.url, '_blank')
      /*
      // kxx 需要记录点击次数 AD没有这个接口 先注释掉
      const { id } = item
      getItem('userInfo') && id && recordVideos({
        type: 'video',
        id,
      }) */
    },
  }
}
</script>

<style scoped lang="scss">
.app-video-list-wrap {
  background-color: #f5f6fa;
  padding:60px 0 0px;
}

.carousel-list-wrap {
  display: flex;
  justify-content: space-between;
  .the-cell {
    width: 216px;
    font-size: 0;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    &.seat-cell {
      height: 0;
    }
    .btn-center-wrap {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    .the-mask {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.3);
    }
    .icon-play{
      width: 38px;
      height: 40px;
     }
    &:hover {
      .btn-play {
        transform: scale(1.1);
      }
      .the-mask {
        background-color: rgba(0,0,0,.6);
      }
    }
    /* &:hover {
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
      }
    } */
     /* &.nocursor {
      &:hover {
        &:after {
          content: '';
          display: none;
        }
      }
    } */
    img {
      width: 100%;
      height: 160px;
      border-radius: 5px;
    }
  }
}
</style>
