module.exports = {
  // format: (d) => {
  //   return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
  // }
  // 等分数组
  groupArray(array, subGroupLength) {
    let index = 0;
    let newArray = [];
    while(index < array.length) {
      let childArr = array.slice(index, index += subGroupLength)
      if (childArr.length < subGroupLength){
          var len= subGroupLength - childArr.length
          for (let i=0; i<len; i++) {
            childArr.push({})
          }
      }
      newArray.push(childArr)
    }
    return newArray
  },
  format: (d) => {
    return (d.getMonth() + 1) + '' + d.getDate() + '-' + d.getHours() + d.getMinutes();
  }
}