<template>
  <div class="info-tips-wrap">
    <div class="ad-container flex-align-center ">
      <i class="icon-notice-horn mr10"></i>
      昨日新增收录 <span class="txt-tips color-tips">{{ dailyIncr }}</span> 条优质闲置链接
    </div>
  </div>
</template>

<script>
import { searchDailyIncr } from '@/api/home.js'
export default {
  name: 'info-tips',
  components: {},
  props: {},
  data() {
    return {
      addNum: 16,
      dailyIncr: 0,
    }
  },
  computed: {},
  watch: {},
  created() {
    this.searchDailyIncr()
  },
  methods: {
    searchDailyIncr() {
      searchDailyIncr().then(res => {
        let { data, success }  = res
        if (success) this.dailyIncr = data
      })
    },
  }
}
</script>

<style scoped lang="scss">
  .info-tips-wrap {
    height: 40px;
    background: #FFF0EC;
    /* margin-top: -61px; */
    line-height: 40px;
    font-size: 14px;
    color: #373E4F;
    .txt-tips {
      padding: 0 3px;
      font-weight: bold;
    }
  }
</style>
