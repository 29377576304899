<template>
  <div class="client-comment-box">
    <div class="container">
      <div class="txt-title">
        <div class="left-box"></div>
        <span>客户评价</span>
      </div>
      <div class="comment-box">
        <div
          class="comment-item"
          v-for="(item, index) in commentItemList"
          :key="index"
        >
          <div :class="['client-head', 'client-head' + index]"></div>
          <div class="comment-title"><span></span></div>
          <div class="comment-content">
            <div v-html="item.txt"></div>
            <div>{{ item.appraiser }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'clientComment',
  components: {},
  props: {},
  data () {
    return {
      commentItemList: [
        {
          title: '客户一',
          txt:
            '我们公司基本上每年都要有10-20条闲置链接合并需求，之前都是找服务商去做的，把店铺交给服务商来操作，甚至有时还需要服务商多次操作，很多时候会担心店铺安全，而且操作效率也是大打折扣；另外服务商收费也比较高，一条链接至少花1000多，现在用了暗度后感觉很多东西都自己可控了，费用也便宜了很多。',
          appraiser: '亚马逊华南城亿级卖家A'
        },
        {
          title: '客户二',
          txt:
            '做亚马逊6年了，之前都是自己和运营在找闲置链接，怕有风险不敢给服务商干，花了大量的时间去找闲置链接，好不容易找到后，要么发现评分低、要么就是有差评、要么是被人用过了，真要想找到一条合适的链接，搞不好要花一两天时间，效率实在太低了；后来一次偶然的机会朋友推荐我用暗度来试一试，我把自己类目和关键词输入，一下就找出来20多条链接，节省了我很多时间。',
          appraiser: '亚马逊坂田亿级卖家B'
        },
        {
          title: '专家一',
          txt:
            '我们是做铺货的，之前每天安排人去找链接，结果发现效率很低，现在通过暗度，一个人一天可以找到几百条满足我们要求的闲置链接，效率明显提高，需要的时候一条就能同步几百条选好的链接到亚马逊新站点卖。 ',
          appraiser: '亚马逊河南郑州亿级铺货卖家C'
        },
        {
          title: '专家二',
          txt:
            '暗度帮助亚马逊卖家迅速筛选出最合适、最高性价比的闲置链接，这个切入点我认为是非常好的。之前有不少服务商也推出了帮人改、也有做闲置链接软件卖的，这些服务我都买过，总体体验下来，从账号安全性、性价比来说，我觉得暗度更适合我们卖家。',
          appraiser: '亚马逊山东亿级精铺卖家D'
        },
        /*
        {
          title: '专家一',
          txt:
            '暗度帮助亚马逊卖家迅速筛选出最合适、最高性价比的僵尸链接，这个切入点我认为是非常好的。之前有不少服务商也推出了帮人改、也有做僵尸链接软件卖的，这些服务我都买过，总体体验下来，从账号安全性、性价比来说，我觉得暗度更适合我们卖家。 ',
          appraiser: '亚马逊广州白云区亿级铺货卖家C'
        },
        {
          title: '专家二',
          txt:
            '暗度从帮助卖家节省成本、解决卖家账号安全的角度，切实解决了我们卖家的一些问题，而且还是按次收费，无论是商业模式、还是产品切入点，都非常不错，今年上新品期间，已经让运营操作几条链接了。',
          appraiser: '亚马逊山东亿级精品卖家D'
        },*/
      ]
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style scoped lang="scss">
.container {
  width: 1200px;
}
.client-comment-box {
  display: flex;
  justify-content: center;
  margin: 80px 0 80px;

  .comment-box {
    display: flex;
    justify-content: space-between;
    margin-top: 95px;
    .comment-item {
      position: relative;
      width: 278px;
      box-shadow: 0px 4px 8px 0px rgba(172, 189, 213, 0.4);
      border-radius: 4px;
      .comment-title {
        height: 80px;
        font-size: 28px;
        color: #fff;
        line-height: 60px;
        text-align: center;
        background: url('../../../assets/home/list.png') no-repeat;
        background-size: 100% 100%;
        span {
          position: relative;
          z-index: 999;
        }
      }
      .comment-content {
        padding: 30px 20px 40px;
        font-size: 16px;
        color: #373e4f;
        line-height: 30px;
      }
      .client-head {
        position: absolute;
        top: -70px;
        left: 50%;
        width: 140px;
        height: 140px;
        transform: translate(-50%, 0);
      }
      .client-head0 {
        background: url('../../../assets/home/client-1.png') no-repeat;
        background-size: 100% 100%;
      }
      .client-head1 {
        background: url('../../../assets/home/client-2.png') no-repeat;
        background-size: 100% 100%;
      }
      .client-head2 {
        background: url('../../../assets/home/client-3.png') no-repeat;
        background-size: 100% 100%;
      }
      .client-head3 {
        background: url('../../../assets/home/client-4.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
.txt-title {
  display: flex;
  margin-bottom: 40px;
  .left-box {
    width: 4px;
    height: 26px;
    border-radius: 2px;
    background-color: #1b4eff;
    margin-right: 16px;
  }
  span {
    font-size: 28px;
    color: #373e4f;
    font-weight: 700;
    margin-top: -7px;
  }
}
</style>
