<template>
  <div class="home-page">
    <headers @to-position="toPosition" />
    <bg @to-position="toPosition" />
    <info-tips />
    <search-table ref="search-table" />

    <!-- 视频教程 -->
    <video-list ref="VideoList"/>
    <project-value ref="project-value" />

    <!--闲置链接三大应用场景-->
    <app-scene />
    <client-comment ref="client-comment" />
    <footers ref="footers" />
    <right-icon />
  </div>
</template>

<script>
import Headers from '@/components/Header/index.vue'
import Bg from './components/Bg.vue'
import SearchTable from './components/search-table.vue'
import VideoList from './components/video-list.vue'
import ProjectValue from './components/project-value.vue'
import AppScene from './components/app-scene.vue'
import ClientComment from './components/client-comment.vue'
import Footers from './components/Footer.vue'
import InfoTips from './components/info-tips.vue'
import RightIcon from '@/components/right-icon/index.vue'
export default {
  name: 'Home',
  components: {
    VideoList,
    AppScene,
    Headers,
    InfoTips,
    Bg,
    SearchTable,
    ProjectValue,
    ClientComment,
    Footers,
    RightIcon
  },
  props: {},
  data () {
    return {
      isFixed: false
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    // console.log(this.$refs['search-table'].$el.children[1].offsetTop -75);
  },
  methods: {
    toPosition (params) {
      console.log(params);
      const { index, val } = params
      let scrollDistance = ''
      let timeId = setTimeout(() => {
        this.$nextTick(() => {
          switch (index) {
            case 0:
              scrollDistance = 0
              break
            case 1:
              scrollDistance = this.$refs['project-value'].$el.offsetTop
              if (val === '1') scrollDistance = scrollDistance - 85
              if (this.isFixed) scrollDistance = scrollDistance - 150
              break
            case 2:
              scrollDistance = this.$refs['client-comment'].$el.offsetTop
              if (val === '1') scrollDistance = scrollDistance - 85
              if (this.isFixed) scrollDistance = scrollDistance - 200
              break
            // case 3:
            //   scrollDistance = this.$refs['faq'].$el.offsetTop - 100
            //   break;
            case 4:
              scrollDistance = this.$refs['footers'].$el.offsetTop
              break
            case 5:
              scrollDistance = this.$refs['VideoList'].$el.offsetTop
              if (val === '1') scrollDistance = scrollDistance - 85
              if (this.isFixed) scrollDistance = scrollDistance - 200
              break
          }
          window.scrollTo({
            top: scrollDistance,
            behavior: 'smooth'
          })
          clearTimeout(timeId)
        })
      }, 200)
      this.isFixed = index == 0 ? false : true
    }
  }
}
</script>

<style scoped lang="scss">
.home-page {
  position: relative;
  width: 100%;
  padding-top: 80px;
  min-width: 1200px;
}
</style>
