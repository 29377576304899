<template>
  <div class="cart-wrapper">
    <div
      class="cart-btn"
      @click="onOpen()">
      <i class="icon-buy-cart"></i>
      <span
        v-if="cartTotal"
        class="cartTotal-box">
        {{ cartTotal }}
      </span>
    </div>
    <el-drawer
      class="drawer-wrap"
      size="100%"
      :custom-class="'asd'"
      :modal="false"
      :wrapperClosable="false"
      :visible.sync="drawer">
      <template slot="title">
        <div class="drawer-wrap-tit">
          收藏夹 ({{ cartTotal }})
        </div>
      </template>
      <div class="table-wrap">
        <el-table
          ref="elTable"
          tooltip-effect="dark"
          style="width: 100%"
          :data="tableData"
          :header-cell-style="{ background: '#f2f6fa' }"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="36">
          </el-table-column>
          <el-table-column
            prop="showImageSrc" 
            align="center"
            width="71"
            label="主图"
            fixed>
            <template slot-scope="scope">
              <el-popover
                placement="right-start" 
                width="306"
                trigger="hover"
                :open-delay="600">
                <img
                  v-if="scope.row.showImageSrc"
                  :src="scope.row.showImageSrc"
                  class="popover-img" />
                <img
                  v-else
                  :src="defaultImgUrl"
                  class="popover-img" />
                <img
                  v-if="scope.row.showImageSrc"
                  :src="scope.row.showImageSrc" 
                  slot="reference"
                  class="look-img"  />
                <img
                  v-else
                  :src="defaultImgUrl"
                  slot="reference"
                  class="look-img" />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="stars" 
            width="80"
            align="center" fixed>
            <template slot="header">
              <el-popover 
                placement="bottom" 
                width="200" trigger="hover" 
                content='商品页面的"Customer reviews"分值'>
                <span slot="reference">用户评分</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { stars } }">
              <el-popover placement="bottom" width="250" trigger="hover" :close-delay="0"
                :content="`商品在亚马逊美国站评分为${stars}分`">
                <span slot="reference">
                  {{ stars }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="level2CategoryName" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content="商品的二级类目">
                <span slot="reference">类目</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { level2CategoryName } }">
              <el-popover placement="bottom" width="225" trigger="hover" :close-delay="0"
                :content="`商品所在类目的二级类目为：${level2CategoryName}`">
                <span slot="reference">
                  {{ level2CategoryName }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column width="60" prop="showBrand" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content='商品页面的"Brand"'>
                <span slot="reference">品牌</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { showBrand } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0" :content="
                showBrand
                  ? `商品的品牌名为: ${showBrand}`
                  : `该商品没有填写品牌`
              ">
                <span slot="reference">
                  <span v-if="showBrand">{{ showBrand }}</span>
                  <div v-else style="min-height: 20px; min-width: 20px"></div>
                </span>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column width="100" prop="totalReview" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover"
                content='商品页面的"global ratings"，当评价被平台删除后，评价数不会变化，所以会存在评价数和实际看到的评价对不上'>
                <span slot="reference">文字评价数</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { totalReview } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0" :content="`商品有${totalReview}个评价`">
                <span slot="reference">
                  {{ totalReview }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="customerReviews" align="center" width="120" fixed>
            <!-- 商品页面评价中的评分占比共100%，显示顺序为5星-4星-3星-2星-1星，具体显示为各星级评分占比 -->
            <template slot="header">
              <el-popover placement="bottom" trigger="hover"
                content="商品页面评价中的评论和Rating总评分占比共100%，显示顺序为5星-4星-3星-2星-1星，具体显示为各星级评分占比">
                <span slot="reference">评价占比</span>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="hover" :close-delay="0" :content="`商品所有评价中各星级评分占比`">
                <div slot="reference">
                  <div class="progress-box">
                    <div class="progress">
                      <div class="size">
                        {{ getCustomerReviews(scope.row, 5) }}
                      </div>
                      <div :style="{ height: getHeight(scope.row, 5) }"></div>
                      <div class="size">5星</div>
                    </div>
                    <div class="progress">
                      <div class="size">
                        {{ getCustomerReviews(scope.row, 4) }}
                      </div>
                      <div :style="{ height: getHeight(scope.row, 4) }">1</div>
                      <div class="size">4星</div>
                    </div>
                    <div class="progress">
                      <div class="size">
                        {{ getCustomerReviews(scope.row, 3) }}
                      </div>
                      <div :style="{ height: getHeight(scope.row, 3) }">1</div>
                      <div class="size">3星</div>
                    </div>
                    <div class="progress">
                      <div class="size">
                        {{ getCustomerReviews(scope.row, 2) }}
                      </div>
                      <div :style="{ height: getHeight(scope.row, 2) }">1</div>
                      <div class="size">2星</div>
                    </div>
                    <div class="progress">
                      <div class="size">
                        {{ getCustomerReviews(scope.row, 1) }}
                      </div>
                      <div :style="{ height: getHeight(scope.row, 1) }">1</div>
                      <div class="size">1星</div>
                    </div>
                  </div>
                  <div class="ta-center f12">
                    评价总数{{ getTotalView(scope.row) }}个
                  </div>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="showTopStars" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content="商品页面第一页前8个评价的评分">
                <span slot="reference">首页评分情况</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { showTopStars } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0" :content="`商品首页前8个评价具体展示分布`">
                <span slot="reference">
                  {{ showTopStars }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          
          <el-table-column prop="gradeScore" align="center" fixed>
            <template slot="header">
              <el-popover placement="bottom" width="200" trigger="hover" content="根据商品综合信息，本系统给出的评分，分值越高越有价值">
                <span slot="reference">暗度评分</span>
              </el-popover>
            </template>
            <template slot-scope="{ row: { gradeScore } }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0"
                :content="`商品在暗度算法中得${gradeScore}分，分值越高越有价值`">
                <span slot="reference">
                  {{ gradeScore }}
                </span>
              </el-popover>
            </template>
          </el-table-column>

          <!-- <el-table-column
            type="cont"
            label="内容">
            <template slot-scope="{ row }">
              <div class="cont-row-box">
                <div class="row-tit">
                  {{ row.level2CategoryName }} 
                </div>
                <div class="row-cont">
                  <span>
                    {{ row.showBrand }} 
                  </span>
                  <span>
                    {{ row.gradeScore }} 分
                  </span>
                </div>
              </div>
            </template>
          </el-table-column> -->

          <el-table-column
            type="price"
            align="center"
            label="金币"
            fixed>
            <template slot-scope="{ row }">
              <span class="price-box">
                {{ row.price }} 
                <!-- 金币 -->
              </span>
              <div
                v-if="errorMap[row.goodId]"
                class="error-msg-row-box"> 
                {{ errorMap[row.goodId].message }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="operate-region-box">
        <div> 
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="isCheckAll"
            @change="onIsCheckAll">
            全选
            </el-checkbox>  
        </div>

        <div> 
          <el-button
            size="small"
            @click="onRemove">
            移出
          </el-button>
          <el-button
            size="small"
            class="fs-btn-warning"
            :disabled="footLoading"
            :loading="footLoading"
            @click="onFoot">
            结算
          </el-button>  
        </div>
      </div>
    </el-drawer>

    <el-dialog
      width="500px"
      :title="dialogTitle"
      :visible.sync="dialogVisible">
      <div class="dialog-cont" v-if="dialogType === 'remove'">
        <i class="el-icon-warning fs-icon-w"></i>
        确认移出选中链接吗？
      </div>
      <div class="dialog-cont" v-else>
        <i class="el-icon-warning fs-icon-w"></i>
        确认结算选中链接吗？
      </div>
      <span
        slot="footer"
        class="dialog-footer">
        <el-button
          @click="dialogVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          class="confirm-btn"
          @click="onConfirm">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <choose-dialog
      @onConfirm="confirmOrder"
      ref="chooseDialog"
      />
    <balance-dialog
      ref="balanceDialog"
      />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import defaultImgUrl from '@/assets/home/default.png'
import chooseDialog from './choose-dialog.vue'
import balanceDialog from './balance-dialog.vue'

import {
  userInfo as userInfoApi,
} from '@/api/user.js'

import {
  queryCarCoin,
  createCarOrder,
  userConfig,
} from "@/api/home.js";

export default {
  components: {
    chooseDialog,
    balanceDialog,
  },
  data() {
    return {
      defaultImgUrl,
      drawer: false,
      isCheckAll: false,
      isIndeterminate: false,
      tableData: [],
      checkData: [],
      dialogVisible: false,
      dialogTitle: '移出提示',
      dialogType: '',
      carCoinData: {
        goldNum: 0,
        buyNoticeFlag: false,
      },
      userInfo: null,
      footLoading: false,
      errorMap: {},
    }
  },
  computed: {
    ...mapState({
      asinCollectList: state => state.user.asinCollectList,
    }),
    cartTotal() {
      const {
        asinCollectList = [],
      } = this
      return asinCollectList.length || 0
    },
  },
  watch: {
    asinCollectList: {
      handler(list) {
        // console.log('asinCollectList', list)
        this.tableData = [...list]
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onOpen() {
      this.drawer = true
    },
    getTotalView(row) {
      const { customerReviews } = row;
      let obj = {};
      try {
        obj = JSON.parse(customerReviews);
      } catch (error) {
        console.log(error);
      }
      return obj.totalView || 0;
    },
    getCustomerReviews(val, index) {
      return JSON.parse(val.customerReviews)[`reviewStar${index}Percent`] ===
        null
        ? "0%"
        : JSON.parse(val.customerReviews)[`reviewStar${index}Percent`];
    },
    getHeight(val, index) {
      let str = JSON.parse(val.customerReviews)[`reviewStar${index}Percent`];
      if (!str) {
        str = 0;
      } else {
        str = str.replace("%", "");
      }
      return str * 0.25 + "px";
    },
    handleSelectionChange(scope) {
      const len = scope.length
      if (!len) {
        this.isCheckAll = false
        this.isIndeterminate = false
        this.checkData = []
      } else {
        if (len === this.cartTotal) {
          this.isCheckAll = true
          this.isIndeterminate = false
        } else {
          this.isCheckAll = false
          this.isIndeterminate = true
        }
        this.checkData = [...scope]
      }
    },
    onIsCheckAll(event) {
      this.isIndeterminate = false
      const $elTable = this.$refs.elTable
      $elTable.clearSelection()
      if (event) {
        $elTable.toggleAllSelection(true)
        this.checkData = [...this.tableData]
      } else {
        this.checkData = []
      }
    },

    onRemove() {
      const { checkData, } = this
      if (!checkData.length) {
        return this.$message.warning('请勾选数据')
      }
      this.dialogTitle = '移出提示'
      this.dialogVisible = true
      this.dialogType = 'remove'
      /* const tips = '确认移出选中链接吗？'
      this.$confirm(tips, '移除提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        iconClass: 'ax',
        customClass: 'the-confirm-box',
      }) */
    },
    onFoot() {
      const { checkData, } = this
      if (!checkData.length) {
        return this.$message.warning('请勾选数据')
      }
      this.dialogTitle = '结算提示'
      this.dialogVisible = true
      this.dialogType = 'foot'
    },
    onConfirm() {
      this.dialogVisible = false
      const { dialogType } = this
      if (dialogType === 'remove') {
        this.confirmRemove(this.checkData)
      } else {
        this.confirmFoot()
      }
    },
    confirmRemove(removeList) {
      const { 
        tableData,
      } = this
      const removeGoodId = removeList.map(el => el.goodId)
      const list = tableData.filter(el => {
        if (removeGoodId.includes(el.goodId)) {
          return false
        }
        return true
      })
      this.$store.commit('setAsinCollectList', list)
    },
    async confirmFoot() {
      const { checkData, } = this
      const goodIds = checkData.map(el => el.goodId)
      this.footLoading = true
      /* 
      if (!userInfo) {
        await this.getUserInfo()
      } 
      */
      await this.getUserInfo()
      if (!this.userInfo) {
        console.log('没有获取到用户信息...')
        this.footLoading = false
        return
      }
      const res = await queryCarCoin({ goodIds })
      this.footLoading = false

      const { success, data = {} } = res
      if (success) {
        this.carCoinData = { ...data }
        const { 
          goodPrice, buyNoticeFlag,
        } = data
        /**
        if (goodPrice > this.userInfo.giftCoinNum) { // 金币不够
        */
        let { giftCoinNum = 0, coinNum = 0} = this.userInfo
        let hasNum = giftCoinNum + coinNum
        if (goodPrice > hasNum) { // 金币不够
          // this.$router.push('/recharge') // 前往充值页面
          this.$refs.balanceDialog.show()
          return
        }

        if (buyNoticeFlag) {
          this.createCarOrder(goodIds)
        } else {
          this.$refs.chooseDialog.show({
            goodPrice, buyNoticeFlag,
          })
        }
      }
    },
    async createCarOrder(goodIds) {
      this.footLoading = true
      const res = await createCarOrder({ goodIds })
      this.footLoading = false
      const { message, success, data } = res
      if (success) {
        let sucGoods = []
        let dataMap = data.reduce((obj, el) => {
          if (el.success) {
            sucGoods.push(el)
          } else {
            obj[el.goodId] = el
          }
          return obj
        }, {})
        this.errorMap = dataMap
        this.confirmRemove(sucGoods)
        /* const { 
          tableData,
        } = this
        const list = tableData.map(el => {
          let row = dataMap[el.goodId]
          if (row && row.success === false) {
            el = {
              ...el,
              errorMsg: row.message,
            }
          }
          return el
        })
        this.tableData = list */
      } else {
        this.$message.error(message)
      }
    },
    async confirmOrder(config) {
      const { buyNoticeFlag } = config
      if (buyNoticeFlag) {
        userConfig({
          configKey: 'buyorder_notice_flag',
          configValue: 'false',
        })
      }

      const { checkData } = this
      const goodIds = checkData.map(el => el.goodId)
      this.createCarOrder(goodIds)
    },
    async getUserInfo() {
      const res = await userInfoApi()
      const { success, data } = res
      if (success) {
        this.userInfo = { ...data }
      } else {
        this.userInfo = null
      }
    },
  },
}
</script>

<style scoped lang="scss">
/* eslint-disable */
.drawer-wrap {
  left: unset;
  width: 900px;
  overflow: unset;
  // z-index: 2022 !important;
  /* box-shadow: 0px 2px 8px 0px rgba(218, 225, 237,80%); */
  /* box-shadow: 
    0 8px 10px -5px rgba(0, 0, 0, .2), 
    0 16px 24px 2px rgba(0, 0, 0, .14), 
    0 6px 30px 5px rgba(0, 0, 0, .12); */
  ::v-deep .el-drawer__header {
    border-bottom: 1px solid #E8E9F0;
    margin-bottom: 0px;
    padding: 20px;
  }
  .drawer-wrap-tit {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #373E4F;
  }
}

.table-wrap {
  height: 100%;
  padding-bottom: 60px;
  overflow-y: auto;
  overflow-x: hidden;
  .el-table__row {
    position: relative;
  }
  .error-msg-row-box {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    right: 0;
    // width: 360px;
    width: 860px;
    height: 100%;
    background: rgba(0,0,0,.5);
    color: #f5f5f5;
    z-index: 10;
  }

  ::v-deep.el-table__header {
    .cell {
      color: #373e4f;
    }
  }
}
.operate-region-box {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  z-index: 10;
  padding: 0 20px;
}

.look-img {
  width: 50px;
  cursor: pointer;
}
.popover-img {
  width: 280px;
  height: 280px;
  cursor: pointer;
}

.cont-row-box {
  font-size: 14px;
  .row-tit {
    color: #373E4F;
  }
  .row-cont {
    display: flex;
    color: #596178;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.cart-btn {
  position: fixed;
  top: 520px;
  right: 8px;
  z-index: 2000;

  width: 68px;
  height: 68px;
  background: linear-gradient(0deg, #FF604E, #FFA853);
  // border-radius: 34px;
  border-radius: 6px;
  box-shadow: 0px 1px 8px 0px rgba(255,132,81,0.18);
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  .icon-buy-cart {
    display: inline-block;
    width: 38px;
    height: 30px;
    background: url('~@/assets/icons/icon-buy-cart-hover.png')
      no-repeat;
    background-size: 100% 100%;
  }
  .cartTotal-box {
    position: absolute;
    background-color: #FF455B;
    color: #fff;
    right: -4px;
    top: -6px;
    border-radius: 10px;
   
    /* width: 16px;
    height: 16px; */
    padding:0 4px;
    min-width: 16px;
    text-align: center;
    font-size: 12px;
    box-shadow: 0px 1px 8px 0px rgba(255,132,81,0.18);
  }
}

.price-box {
  // font-size: 16px;
  font-family: Source Han Sans CN;
  // font-weight: 500;
  color: #FFA200;
  font-size: 22px;
  font-weight: bold;
}

.the-confirm-box {
  ::v-deep .el-message-box__btns {
    text-align: center;
  }
  .el-message-box__btns {
    text-align: center;
  }
}

.cart-wrapper {
  .dialog-cont {
    color: #373E4F;
    text-align: center;
    font-size: 16px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fs-icon-w {
    color: #ffa300;
    font-size: 24px;
    margin-right: 4px;
  }
  ::v-deep.el-dialog__footer {
    position: relative;
    padding: 0 32px 20px;
    .dialog-footer {
      display: flex;
      // justify-content: space-between;
      justify-content: center;

      .el-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 46px;
        color: #373e4f;
      }

      .confirm-btn {
        background: linear-gradient(0deg, #0831ff, #3777ff);
        color: #fff;
        // margin-left: 50px;
      }

      .el-checkbox {
        position: absolute;
        bottom: 32px;
        left: 22px;
      }
    }
  }
}


.progress-box {
  display: flex;
  justify-content: space-between;
  width: 108px;

  .progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-left: -10px;

    .size {
      width: 32px;
      font-size: 12px;
      transform: scale(0.8);
    }

    div:nth-child(2) {
      width: 6px;
      height: 25px;
      border-radius: 3px;
      color: transparent;
    }
  }

  .progress:nth-child(1) {
    div:nth-child(2) {
      background: #ff455b;
    }
  }

  .progress:nth-child(2) {
    div:nth-child(2) {
      background: #fdb944;
    }
  }

  .progress:nth-child(3) {
    div:nth-child(2) {
      background: #00ffa2;
    }
  }

  .progress:nth-child(4) {
    div:nth-child(2) {
      background: #0668f6;
    }
  }

  .progress:nth-child(5) {
    div:nth-child(2) {
      background: #5883ff;
    }
  }
}


</style>
