<template>
  <el-carousel class="banner-wrap" indicator-position="none" height="180px" :interval="5000">
    <!-- <el-carousel-item>
      <a href="https://www.lianjiebaobei.com/home?zbuc=C67401">
        <img style="width: 100%" src="@/assets/activity/banner.png" /></a>
    </el-carousel-item> -->
    <!--
    <el-carousel-item>
      <a href="/recharge">
        <img style="width: 100%" src="@/assets/activity/banner.jpeg" /></a>
    </el-carousel-item>
    -->
    <el-carousel-item v-for="(item, index) in list" :key="index">
      <div class="banner-cell cursor-pointer" @click="goToPage(item)">
        <img :src="item.url" :alt="item.title" 
          @click="item.onClick"/>
        <span class="examine" @click.stop="video(index)"></span>
      </div>
    </el-carousel-item>
  </el-carousel>
  <!-- <div class="bg-box">
    <img
      src="../../../assets/home/bg.png"
      alt=""
    />
  </div> -->
</template>

<script>
import banner1 from "@/assets/home/banner2.png";
import banner2 from "@/assets/home/banner1-1.jpg";
import banner3 from "@/assets/home/banner1-2.jpg";
import banner5 from "@/assets/home/banner5.jpg"
// import banner6 from "@/assets/home/banner6.jpg"
// import banner7 from "@/assets/home/banner7.jpg"
import banner8 from "@/assets/home/banner8.png"

export default {
  name: "bg", // banner区域
  components: {},
  props: {},
  data() {
    return {
      list: [
       {
          title: '优质僵尸链接',
          url: banner8,
          id: 8,
          onClick: () => {
          },
        },
        /* {
          title: '2023元旦活动',
          url: banner6,
          id: 6,
          onClick: () => {
            this.$router.push('/recharge')
          },
        }, */
        /* {
          title: "38妇女节活动",
          url: banner7,
          id: 7,
          onClick: () => {
            this.$router.push('/recharge')
          },
        }, */
        {
          title: "小佈ERP",
          url: banner5,
          id: 5,
          onClick: () => {
            window.open('https://h5.erpbool.com/bool?qd=wxh&cy=YM100007', '_blank')
          },
        },
        {
          title: "暗度购物车收藏功能上线",
          url: banner2,
          id: 1,
          onClick: () => {
          },
        },
        {
          title: '僵尸闲置链接平台',
          url: banner3,
          id: 1,
          onClick: () => {
          },
        },
        {
          title: "暗度使用方法一键学习，快来点击查看学习",
          url: banner1,
          id: 1,
          onClick: () => {
          },
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() { },
  methods: {
    goToPage(item) {
      const { id } = item;
      if (id === 2) {
        this.$router.push("/fqa");
      }
      /* if (getItem('userInfo')) {
        this.$router.push('/invite-activity')
      } else {
        this.$eventBus.$emit('openLoginDV')
      } */
    },
    video(item) {
      if (item == 1) {
        this.$emit("to-position", {
          index: +5,
          val: "0",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.banner-wrap {
  min-width: 1200px;
  height: 180px;

  .banner-cell {
    width: 100%;
    height: 180px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.bg-box {
  width: 100%;
  height: 270px;
  margin: 0 0 60px;

  img {
    width: 100%;
    height: 100%;
  }
}

.examine {
  display: inline-block;
  width: 250px;
  height: 60px;
  position: relative;
  top: -50%;
  left: 40%;
  //  background-color: aqua;
}
</style>
