<template>
  <div id="appSceneBox" class="app-scene-wrap">
    <div class="kad-container">
      <div class="kad-card-la-title">
        闲置链接三大应用场景
      </div>
      <div class="kax-box">
        <div class="kax-box-content">
          <div class="kax-box-content-text">
            <p><span class="stress-cell">场景一：</span>卖家新品没有评价，数据表现不佳，又不敢测评，此时卖家可以用来本国链接合并，提高新品表现；卖家新品老品链接评分不好，整体数据表现下滑，此时可以用来合并维护老品评价，建议最好要合规变体。</p>
            <p><span class="stress-cell">场景二：</span>卖家跨站点销售时，新品缺评价，老品评分低时，卖家可以用来同步国际评价合并，提高Listing评分，国际评价同步建议最好要合规变体。</p>
            <p><span class="stress-cell">场景三：</span>卖家国际同步销售时，新品缺评价，可以直接同步到对应站点开展销售，适合于铺货销售，特别是小站点泛铺，可以同步美国闲置链接直接到对应站点销售。</p>
          </div>
          <div class="kax-box-tips">以上最好要注意同步链接时要注意各站点品牌是否有侵权事宜。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-scene',
  components: {},
  props: {},
  data() {
    return {
      list: [
      ]
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.app-scene-wrap {
  background-color: #f5f6fa;
  padding-bottom: 80px;
}
</style>
